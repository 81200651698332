import React from "react"
import "./Categories.css"
import Cdata from "./Cdata"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Categories = () => {
  
  function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "none" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "none" }}
      onClick={onClick}
    />
  );
}

    const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <section className='CategoriesMain'>
        <div className='container_categories'>
          <div className='heading_categories d_flex_categories'>
            <div className='heading-left f_flex_categories'>
              <h2>Categories</h2>
            </div>
            <div className='heading-right'>
              <a href="https://www.mafazgroup.shop/category/all-products">View All</a>
            </div>
          </div>
        <div className="product">
          <Slider {...settings}>
              {Cdata.map((val, index) => {
                return (
                  <div className='box' key={index}>
                    <div className='img'>
                      <img src={val.cover} alt='' />
                    </div>
                    <h4>{val.name}</h4>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  )
}

export default Categories;
