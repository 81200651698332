import { BsFillBagFill } from "react-icons/bs";

const Card = ({ img, title, packing, reviews, prevPrice, newPrice }) => {
  return (
    <>
      <section className="card">
        <img src={img} alt={title} className="card-img" />
        <div className="card-details">
          <h3 className="card-title">{title}</h3>
          <section className="card-price">
            <div className="price">
            {packing}
            </div>
          </section>
          <button className='btn-primary'><a href="https://www.mafazgroup.shop/category/all-products">Order Now</a></button>
          </div>
      </section>
    </>
  );
};

export default Card;
