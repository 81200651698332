import React from "react";
import "./Sector.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdChevronLeft,MdChevronRight } from "react-icons/md";


const Sector = () => {

    const slides = [
        {image:"/images/sectors/hotels.jpg", title:"Hotels", /*description:"this is a description", clickEvent:sliderClick*/},
        {image:"/images/sectors/restaurant.jpg", title:"Restaurants", /*description:"this is 4th description", clickEvent:sliderClick*/},
        {image:"/images/sectors/coffee shops.jpg", title:"Coffee Shops", /*description:"this is 5th description", clickEvent:sliderClick*/},
        {image:"/images/sectors/supermarket.jpg", title:"Supermarkets/Hypermarkets", /*description:"this is 5th description", clickEvent:sliderClick*/},
        {image:"/images/sectors/bakery.jpg", title:"Bakeries", /*description:"this is 2nd description", clickEvent:sliderClick*/},
        {image:"/images/sectors/catering.jpg", title:"Catering Services", /*description:"this is 3rd description", clickEvent:sliderClick*/},
        {image:"/images/sectors/hospitals.jpg", title:"Hospitals", /*description:"this is a description", clickEvent:sliderClick*/},
        {image:"/images/sectors/cinemas.jpg", title:"Cinemas", /*description:"this is 2nd description", clickEvent:sliderClick*/},
        {image:"/images/sectors/wholesale.jpg", title:"Wholesale", /*description:"this is 3rd description", clickEvent:sliderClick*/},
        {image:"/images/sectors/food productions.jpg", title:"Food Productions", /*description:"this is 4th description", clickEvent:sliderClick*/},
        {image:"/images/sectors/meat poultry.jpg", title:"Meat & Poultry", /*description:"this is 5th description", clickEvent:sliderClick*/},

        ];
    
    const NextArrow = ({ onClick }) => {
        return (
        <div className="arrow next" onClick={onClick}>
            <MdChevronRight size={40}/>
        </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
        <div className="arrow prev" onClick={onClick}>
            <MdChevronLeft size={40}/>
        </div>
        );
    };

    const settings = {
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
        <section className="SectorsMain">
          <div className='container_sector'>
            <div className='heading_setors d_flex_setors'>
              <div className='heading-left f_flex_setors'>
                <h2>Sectors We Serve</h2>
              </div>
              <div className='heading-right'>
                <a href="https://www.mafazgroup.shop/category/all-products">View All</a>
              </div>
            </div>
            <div className="sector_product">
              <Slider {...settings}>
                  {
                  slides.map((slide, index) => {
                      return (
                          <div className="sector_content sector_grid">
                            <div className="sector_box">
                              <div className='sector_img'><img src={slide.image} alt='' /></div>
                              <h4>{slide.title}</h4>
                            </div>
                          </div>
                      )
                  })
                  }
              </Slider>
              </div>
            </div>
        </section>
    );
};

export default Sector;